import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';

import APIGet from '../../../API/APIGet';
import APIPost from '../../../API/APIPost';
import APIPut from '../../../API/APIPut';
import APIDelete from '../../../API/APIDelete';
import APIHelper from '../../../Helper/APIHelper';
import AuthHelper from '../../../Helper/AuthHelper';

import ThingsToSelect from '../../../Helper/ThingsToSelect';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import LoadingCenter from '../../../Components/LoadingCenter';

import Row from './Row';

export default class LandOperators extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			landOperators: [],
			isEditLoading: '',
		};
	}

	async componentDidMount() {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			console.log('componentDidMount landOperators');
			let req = await APIGet('landOperators');
			if (APIHelper.handleAPIResponse(this, req)) {
				const landOperators = req.data;
				console.log('landOperators: ', landOperators);
				this.setState({
					isLoading: false,
					landOperators,
				});
			}
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}

	saveChange = async (landOperatorId, name, country) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPut('landOperators/' + landOperatorId, {
			name: name,
			country: country,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const landOperator = request.data;
			let landOperators = JSON.parse(JSON.stringify(this.state.landOperators));
			for (let i = 0; i < landOperators.length; i++) {
				if (landOperators[i].id === landOperatorId) {
					landOperators[i] = landOperator;
				}
			}

			// console.log(landOperators);
			toast.success('แก้ไขข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				landOperators,
			});
		} else {
			toast.error('ไม่สามารถแก้ไขข้อมูลได้');
		}
	};

	addNew = async (name, country) => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIPost('landOperators', {
			name: name,
			country: country,
		});

		if (APIHelper.handleAPIResponse(this, request)) {
			const landOperator = request.data;
			let landOperators = JSON.parse(JSON.stringify(this.state.landOperators));
			landOperators.push(landOperator);

			// console.log(landOperators);
			toast.success('เพิ่มข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				landOperators,
			});
		} else {
			toast.error('ไม่สามารถเพิ่มข้อมูลได้');
		}
	};

	delete = async landOperatorId => {
		if (this.state.isEditLoading === '') {
			this.setState({
				isEditLoading: 'is-loading',
			});
		}

		const request = await APIDelete('landOperators/' + landOperatorId);
		if (APIHelper.handleAPIResponse(this, request)) {
			const landOperators = this.state.landOperators.filter(landOperator => landOperator.id !== landOperatorId);

			// console.log(landOperators);
			toast.success('ลบข้อมูลเรียบร้อยแล้ว');
			this.setState({
				isEditLoading: false,
				landOperators,
			});
		} else {
			toast.error('ไม่สามารถลบได้');
		}
	};

	render() {
		if (this.state.isLoading) {
			return <LoadingCenter />;
		}

		return (
			<div className="container">
				<BackButtonRouter />
				<div className="columns">
					<div className="column is-6">
						<h1 className="title is-2">Land Operators</h1>
					</div>
					<div className="column is-6" />
				</div>
				<div className="section table-container">
					<table className="table is-hoverable is-fullwidth">
						<tbody>
							{this.state.landOperators.map(landOperator => (
								<tr key={landOperator.id}>
									<td width="100%">
										<Row
											id={landOperator.id}
											name={landOperator.name}
											country={landOperator.country}
											confirmChange={(name, country) => this.saveChange(landOperator.id, name, country)}
											confirmDelete={this.delete}
										>
											{landOperator.name} ({landOperator.country})
										</Row>
									</td>
								</tr>
							))}
							<tr>
								<td width="100%">
									<Row name="" country="" confirmChange={this.addNew} confirmText="เพิ่ม">
										เพิ่ม Land Operator
									</Row>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
