import React from 'react';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import { EditableTextareaSwitch } from '../../../../Components/EditableTextareaSwitch';

export const Remark = ({ state, onChange }) => {
	const { isEditing, remark } = state;
	return (
		<div className="columns" style={{ width: '100%', alignItems: 'center' }}>
			<div className="column is-1">
				<label className="label" style={{ fontSize: 22, color: 'black' }}>
					Note :
				</label>
			</div>
			<div className={'column is-10'}>
				<EditableTextareaSwitch isEditing={isEditing} value={remark ? remark : ''} onChange={remark => onChange(remark)} />
			</div>
		</div>
	);
};
