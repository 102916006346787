import React, { useMemo } from 'react';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import { SumTable } from './SumTable';

import ThingsToSelect from '../../../../Helper/ThingsToSelect';

import ADULT_CHILD from '../../../../StaticData/AdultChild';
import PLANNING_EXPENSE_TYPES from '../../../../StaticData/PlanningExpenseTypes';
import { DEFAULT_LAND_ASSESSMENT } from '../../../../StaticData/SubPlanTemplate';
import { Remark } from './Remark';
import { mapCountryFromId } from '../../../../Helper/Mapper';

export const LandAssessmentTabs = ({ selectedSubPlan, state, onChange, onRemarkChange }) => {
	const { isEditing, selectedTab, exchangeRates, countries } = state;

	if (selectedTab !== 'landAssessment') {
		return <></>;
	}

	if (!selectedSubPlan) return <></>;

	const landAssessments = selectedSubPlan.landAssessments;

	if (!landAssessments) return <></>;

	console.log('landAssess', landAssessments);

	return (
		<div style={{ paddingLeft: 100, paddingRight: 100, paddingTop: 50, display: selectedTab === 'landAssessment' ? 'block' : 'none' }}>
			<Remark state={state} onChange={onRemarkChange} />
			<p className="" style={{ backgroundColor: '#209CEE', fontSize: 25, fontWeight: 'bold', color: 'white', padding: 5, paddingLeft: 10 }}>
				Land Arrangement
			</p>
			<div style={{ height: '30px' }} />
			{landAssessments && Object.keys(landAssessments).length > 0
				? Object.keys(landAssessments).map((countryId, index) => {
						const landAssessmentByCountry = landAssessments[countryId];
						if (!landAssessmentByCountry) {
							return null;
						}

						// if (countries.filter(country => country.value === parseInt(countryId)).length === 0) {
						// 	return null;
						// }

						return (
							<LandAssessmentByCountry
								key={`landAssessmentByCountry-${index}`}
								landAssessmentByCountry={landAssessmentByCountry}
								onChange={newLandAssessmentByCountry => {
									onChange(newLandAssessmentByCountry, countryId);
								}}
								country={mapCountryFromId(countryId, state.allCountries)}
								exchangeRates={exchangeRates}
								isEditing={isEditing}
							/>
						);
				  })
				: null}
			<div />
		</div>
	);
};

const LandAssessmentByCountry = ({ landAssessmentByCountry, onChange, isEditing, exchangeRates, country }) => {
	if (!landAssessmentByCountry) {
		return null;
	}

	console.log('landAssessmentByCountry', landAssessmentByCountry);

	return (
		<div style={{ marginBottom: 15, marginTop: 15 }}>
			<h4 className="title is-4">ประเทศ: {country ? country.label : ''}</h4>
			<div style={{ paddingTop: 10, paddingBottom: 10 }} />
			{landAssessmentByCountry.map((assessment, index) => (
				<LandAssessmentRow
					key={`assessment-${index}`}
					assessment={assessment}
					exchangeRates={exchangeRates}
					index={index}
					isEditing={isEditing}
					onChange={(index, newAssessment) => {
						onChange([
							...landAssessmentByCountry.slice(0, index),
							newAssessment,
							...landAssessmentByCountry.slice(index + 1, landAssessmentByCountry.length),
						]);
					}}
					onRemove={index => {
						onChange([...landAssessmentByCountry.slice(0, index), ...landAssessmentByCountry.slice(index + 1, landAssessmentByCountry.length)]);
					}}
				/>
			))}
			<div style={{ height: '50px' }} />
			{isEditing ? (
				<button
					className="button is-info"
					onClick={e => {
						e.preventDefault();
						console.log('on add called');
						onChange(
							landAssessmentByCountry
								? [
										...landAssessmentByCountry,
										{
											id: null,
											name: null,
											currency: null,
											customerType: null, //child, all
											expenseType: null, // pax avg
											expenses: [
												{
													id: null,
													name: '',
													price: 0,
													quantity: 0,
												},
											],
										},
								  ]
								: DEFAULT_LAND_ASSESSMENT
						);
					}}
				>
					+เพิ่ม assessment ของประเทศ: {country ? country.label : ''}
				</button>
			) : null}
		</div>
	);
};

const LandAssessmentRow = ({ index, isEditing, assessment, exchangeRates, onChange, onRemove }) => {
	const _onChange = (key, value) => {
		const updatedData = JSON.parse(JSON.stringify(assessment));
		updatedData[key] = value;
		onChange(index, updatedData);
	};

	const _onRemoveRecord = expenseIndex => {
		const updatedData = JSON.parse(JSON.stringify(assessment));
		updatedData.expenses = [...updatedData.expenses.slice(0, expenseIndex), ...updatedData.expenses.slice(expenseIndex + 1, updatedData.expenses.length)];
		onChange(index, updatedData);
	};

	const selectedCurrency = useMemo(() => {
		if (assessment.currency && !assessment.currency.label) {
			return {
				value: assessment.currency.id,
				label: assessment.currency.name,
			};
		}

		return assessment.currency;
	}, [assessment.currency]);

	const selectedCustomerType = useMemo(() => {
		if (assessment.customerType && !assessment.customerType.label) {
			if (assessment.customerType === 'adult') {
				return ADULT_CHILD[0];
			} else if (assessment.customerType === 'child') {
				return ADULT_CHILD[1];
			}
		}

		return assessment.customerType;
	}, [assessment.customerType]);

	const selectedExpenseType = useMemo(() => {
		if (assessment.expenseType && !assessment.expenseType.label) {
			if (assessment.expenseType === 'pax') {
				return PLANNING_EXPENSE_TYPES[0];
			} else if (assessment.expenseType === 'avg') {
				return PLANNING_EXPENSE_TYPES[1];
			}
		}

		return assessment.expenseType;
	}, [assessment.expenseType]);

	const cannotEdit = name => {
		if (!name) return false;

		return (
			!isEditing ||
			(index < 3 && (name.toLowerCase() === 'land expense' || name.toLowerCase() === 'client expense' || name.toLowerCase() === 't/l expense'))
		);
	};

	return (
		<div style={{ marginBottom: 30 }}>
			<div className="columns" style={{ justifyContent: 'center' }}>
				<div className="column is-4">
					<div className="level level-item">
						<p style={{ paddingRight: 20 }}>{index + 1}.</p>
						<div className="" style={{ width: '100%' }}>
							<EditableTextSwitch
								isEditing={isEditing}
								type={'text'}
								value={assessment.name}
								onChange={text => {
									_onChange('name', text);
								}}
								disabled={cannotEdit(assessment.name)}
							>
								<p style={{ textAlign: 'left' }}>{assessment.name}</p>
							</EditableTextSwitch>
						</div>
					</div>
				</div>
				<div className="column is-2">
					<Select
						placeholder="สกุลเงิน"
						value={selectedCurrency}
						onChange={currency => {
							_onChange('currency', {
								id: currency.value,
								name: currency.label,
							});
						}}
						options={ThingsToSelect(exchangeRates)}
						isDisabled={!isEditing}
					/>
				</div>
				<div className="column is-2">
					{assessment.name !== 'T/L expense' ? (
						<Select
							placeholder="ผู้ใหญ่/เด็ก"
							value={selectedCustomerType}
							onChange={customerType => {
								_onChange('customerType', customerType.value);
							}}
							options={ADULT_CHILD}
							isDisabled={!isEditing}
						/>
					) : null}
				</div>
				<div className="column is-2">
					<Select
						placeholder="วิธีคิด"
						value={selectedExpenseType}
						onChange={expenseType => {
							_onChange('expenseType', expenseType.value);
						}}
						options={PLANNING_EXPENSE_TYPES}
						isDisabled={cannotEdit(assessment.name)}
					/>
				</div>
				{cannotEdit(assessment.name) ? (
					<div className="column is-1" />
				) : (
					<div className="column is-1" style={{ display: 'flex', alignItems: 'center' }}>
						<FontAwesomeIcon
							icon={faTrashCan}
							style={{ color: isEditing ? 'red' : 'black', cursor: isEditing ? 'pointer' : 'auto' }}
							onClick={e => {
								e.preventDefault();
								if (isEditing) {
									onRemove(index);
								}
							}}
						/>
					</div>
				)}
			</div>
			<div className="" style={{ paddingLeft: 70, paddingRight: 50 }}>
				<table className="" style={{ width: '100%' }}>
					<tbody>
						{assessment.expenses && assessment.expenses.length > 0
							? assessment.expenses.map((expense, expenseIndex) => (
									<tr key={`assessment-${index}-expense-${expenseIndex}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
										<td style={{ width: '3%' }}>•</td>
										<td style={{ width: '35%', paddingRight: 80 }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'text'}
												value={expense.name}
												onChange={text => {
													const updatedData = JSON.parse(JSON.stringify(assessment));
													if (!updatedData.expenses) updatedData.expenses = [];
													updatedData.expenses[expenseIndex] = {
														...expense,
														name: text,
													};

													onChange(index, updatedData);
												}}
											>
												<p style={{ textAlign: 'left' }}>{expense.name}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '20%' }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'number'}
												value={expense.price}
												onChange={text => {
													const updatedData = JSON.parse(JSON.stringify(assessment));
													if (!updatedData.expenses) updatedData.expenses = [];
													updatedData.expenses[expenseIndex] = {
														...expense,
														price: text,
													};

													onChange(index, updatedData);
												}}
											>
												<p style={{ textAlign: 'center' }}>{expense.price}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '1%', paddingLeft: 10, paddingRight: 15 }}>x</td>
										<td style={{ width: '12%' }}>
											<EditableTextSwitch
												isEditing={isEditing}
												type={'number'}
												value={expense.quantity}
												onChange={text => {
													const updatedData = JSON.parse(JSON.stringify(assessment));
													if (!updatedData.expenses) updatedData.expenses = [];
													updatedData.expenses[expenseIndex] = {
														...expense,
														quantity: text,
													};

													onChange(index, updatedData);
												}}
											>
												<p style={{ textAlign: 'center' }}>{expense.quantity}</p>
											</EditableTextSwitch>
										</td>
										<td style={{ width: '15%', display: 'flex', paddingLeft: 10 }}>
											<p style={{ paddingRight: 10 }}>=</p>
											<p>{(expense.price * expense.quantity).toLocaleString()}</p>
										</td>
										<td style={{ width: '5%', display: 'flex', paddingLeft: 10 }}>
											<FontAwesomeIcon
												icon={faTrashCan}
												style={{ color: isEditing ? 'red' : 'black', cursor: isEditing ? 'pointer' : 'auto' }}
												onClick={e => {
													e.preventDefault();
													if (isEditing) {
														_onRemoveRecord(expenseIndex);
													}
												}}
											/>
										</td>
									</tr>
							  ))
							: null}
						<tr style={{ display: 'flex', alignItems: 'center', marginTop: isEditing ? 20 : 0, marginBottom: 15 }}>
							<td style={{ width: '3%' }}>•</td>
							<td style={{ width: '35%' }}>รวม</td>
							<td style={{ width: '20%' }} />
							<td style={{ width: '1%', paddingLeft: 10, paddingRight: 15 }} />
							<td style={{ width: '12%' }} />
							<td style={{ width: '15%', display: 'flex', paddingLeft: 10 }}>
								<p style={{ paddingRight: 10 }}>=</p>
								<p>
									{assessment.expenses && assessment.expenses.length > 0
										? assessment.expenses.reduce((acc, expense) => acc + expense.price * expense.quantity, 0).toLocaleString()
										: 0}
								</p>
							</td>
						</tr>
						<tr>
							<td>
								{isEditing ? (
									<button
										className="button is-success is-small"
										onClick={e => {
											e.preventDefault();
											const expenses = [
												...(assessment.expenses ? assessment.expenses : []),
												{
													id: null,
													name: '',
													price: 0,
													quantity: 0,
												},
											];

											_onChange('expenses', expenses);
										}}
									>
										+เพิ่ม
									</button>
								) : null}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};
