import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthRender from '../AuthRender';
import AppConfig from '../../AppConfig';
import IsMobile from '../../Helper/IsMobile';

import DesktopNotification from './DesktopNotification';

class DesktopRightMenu extends Component {
	isPageOpened = partOfUrl => {
		const check = window.location.href.split(partOfUrl);
		return check.length > 1;
	};

	render() {
		const { isLogin, userDetail } = this.props;
		return (
			<div className="navbar-end">
				{isLogin && !IsMobile() ? <DesktopNotification /> : null}
				{isLogin ? (
					<div className="navbar-item has-dropdown is-hoverable">
						<a className="navbar-link">ลูกค้า & Suppliers</a>
						<div className="navbar-dropdown is-boxed is-right">
							<NavLink to={AppConfig.appPath + '/data/companies'} className="navbar-item clickable" activeClassName="is-active">
								Incentive ลูกค้า
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/suppliers'} className="navbar-item clickable" activeClassName="is-active">
								Incentive Suppliers
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/incentive/restaurants'} className="navbar-item clickable" activeClassName="is-active">
								Incentive Restaurants
							</NavLink>
							<hr className="navbar-divider" />

							<NavLink to={AppConfig.appPath + '/data/jointour/customers'} className="navbar-item clickable" activeClassName="is-active">
								Jointour ลูกค้า
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/jointour/wholesales'} className="navbar-item clickable" activeClassName="is-active">
								Jointour Whole Sales
							</NavLink>

							<hr className="navbar-divider" />

							<NavLink to={AppConfig.appPath + '/data/fit/customers'} className="navbar-item clickable" activeClassName="is-active">
								FIT ลูกค้า
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/fit/suppliers'} className="navbar-item clickable" activeClassName="is-active">
								FIT Suppliers
							</NavLink>
						</div>
					</div>
				) : null}

				{isLogin ? (
					<div className="navbar-item has-dropdown is-hoverable">
						<a className="navbar-link">Data</a>
						<div className="navbar-dropdown is-boxed is-right">
							<AuthRender roles={['Admin']}>
								<NavLink to={AppConfig.appPath + '/data/staffs'} className="navbar-item clickable" activeClassName="is-active">
									Staffs
								</NavLink>
							</AuthRender>
							<hr className="navbar-divider" />

							<NavLink to={AppConfig.appPath + '/data/banks'} className="navbar-item clickable" activeClassName="is-active">
								Banks
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/bankAccounts'} className="navbar-item clickable" activeClassName="is-active">
								Bank Accounts
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/creditCards'} className="navbar-item clickable" activeClassName="is-active">
								Credit Cards
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/countries'} className="navbar-item clickable" activeClassName="is-active">
								ประเทศ
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/airlines'} className="navbar-item clickable" activeClassName="is-active">
								Airlines
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/landOperators'} className="navbar-item clickable" activeClassName="is-active">
								Land Operators
							</NavLink>
							<hr className="navbar-divider" />

							<NavLink to={AppConfig.appPath + '/data/incomeEtcCategories'} className="navbar-item clickable" activeClassName="is-active">
								ประเภทรายรับอื่นๆของทัวร์
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/expenseCategories'} className="navbar-item clickable" activeClassName="is-active">
								ประเภทรายจ่ายของทัวร์
							</NavLink>
						</div>
					</div>
				) : null}

				{isLogin ? (
					<div className="navbar-item has-dropdown is-hoverable is-right">
						<a className="navbar-link">{userDetail.name}</a>
						<div className="navbar-dropdown is-boxed is-right">
							<NavLink to={AppConfig.appPath + '/data/password'} className="navbar-item clickable" activeClassName="is-active">
								Change Password
							</NavLink>
							<NavLink to={AppConfig.appPath + '/data/profile'} className="navbar-item clickable" activeClassName="is-active">
								Edit Profile
							</NavLink>
							<hr className="navbar-divider" />
							<NavLink to={AppConfig.appPath + '/logout'} className="navbar-item clickable" activeClassName="is-active">
								<FontAwesomeIcon icon="sign-out-alt" />
								<span>Logout</span>
							</NavLink>
						</div>
					</div>
				) : (
					<NavLink to={AppConfig.appPath + '/'} className="navbar-item clickable" activeClassName="is-active">
						Login
					</NavLink>
				)}
			</div>
		);
	}
}

export default withRouter(DesktopRightMenu);
