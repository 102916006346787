import React, { Component } from 'react';
import moment from 'moment-timezone';
import { BrowserRouter } from 'react-router-dom';
import SearchFilterContext, { defaultSearchDate } from './Context/SearchFilterContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faSignOutAlt,
	faArrowLeft,
	faFileAlt,
	faUpload,
	faTimes,
	faCircleNotch,
	faCheckCircle,
	faRetweet,
	faBell,
	faMinusCircle,
	faStar,
	faMapPin,
	faStarHalf,
	faStarHalfAlt,
	faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Bulma.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

import AppNavbar from './Components/AppNavBar';
import AppFooter from './Pages/AppFooter';

import Store from './Helper/Store';

import GeneralRoutes from './Routes/General';
import DataRoutes from './Routes/Data';
import FinanceRoutes from './Routes/Finance';
import IncentiveRoutes from './Routes/Incentive';
import JoinTourRoutes from './Routes/JoinTour';
import FITRoutes from './Routes/FIT';
import ReportRoutes from './Routes/Report';
import StoreKey from './StoreKey';
import PlanningIncentiveRoutes from './Routes/PlanningIncentive';

library.add(
	faSignOutAlt,
	faArrowLeft,
	faFileAlt,
	faUpload,
	faTimes,
	faCircleNotch,
	faCheckCircle,
	faRetweet,
	faBell,
	faMinusCircle,
	faStar,
	faMapPin,
	faStarHalf,
	faStarHalfAlt,
	faCopy
);

class App extends Component {
	constructor(props) {
		super(props);

		this.updateSearchFilter = filter => {
			this.setState(state => ({
				...state,
				...filter,
			}));

			Store.set(StoreKey.filterContext, filter);
		};

		let filterFromStorage = Store.get(StoreKey.filterContext);
		let initialFilterState = defaultSearchDate;
		console.log('filter from storage: ', filterFromStorage);
		if (filterFromStorage) {
			try {
				initialFilterState = filterFromStorage;
				initialFilterState.startDate = moment(new Date(initialFilterState.startDate));
				initialFilterState.endDate = moment(new Date(initialFilterState.endDate));
			} catch (ex) {
				console.log(ex);
				initialFilterState = defaultSearchDate;
			}
		}

		this.state = {
			...initialFilterState,
			updateSearchFilter: this.updateSearchFilter,
		};
	}

	render() {
		return (
			<SearchFilterContext.Provider value={this.state}>
				<BrowserRouter>
					<div className="App">
						<AppNavbar />

						<GeneralRoutes />
						<DataRoutes />
						<FinanceRoutes />
						<IncentiveRoutes />
						<PlanningIncentiveRoutes />
						<JoinTourRoutes />
						<FITRoutes />
						<ReportRoutes />

						<AppFooter />
						<ToastContainer position="bottom-right" autoClose={4000} newestOnTop />
					</div>
				</BrowserRouter>
			</SearchFilterContext.Provider>
		);
	}
}

export default App;
