import React, { Component } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import InputRow from '../../../Components/InputRow';

export default class Row extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEditing: false,
			name: props.name,
			country: props.country,
		};
	}

	componentWillReceiveProps = nextProps => {
		this.setState({
			name: nextProps.name,
			country: nextProps.country,
		});
	};

	validateInput = () => {
		const { name, country } = this.state;
		if (name.trim() !== '' && country.trim() !== '') {
			return true;
		}

		return false;
	};

	handleInputChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	toggleMode = () => {
		this.setState({
			isEditing: !this.state.isEditing,
		});
	};

	confirmChange = () => {
		if (this.validateInput()) {
			this.setState({
				isEditing: false,
			});

			this.props.confirmChange(this.state.name, this.state.country);
		} else {
			toast.warn('กรุณากรอกข้อมูลในครบ');
		}
	};

	cancelChange = () => {
		this.setState({
			name: this.props.name,
			country: this.props.country,
			isEditing: false,
		});
	};

	delete = () => {
		this.props.confirmDelete(this.props.id);
	};

	render() {
		if (this.state.isEditing) {
			return (
				<div className="columns">
					<div className="column is-3">
						<InputRow
							labelSize="s"
							title="ชื่อ"
							placeholder="ชื่อ"
							keyProps="name"
							value={this.state.name}
							onInputChange={this.handleInputChange}
							disabled={false}
						/>
					</div>
					<div className="column is-3">
						<InputRow
							labelSize="s"
							title="ประเทศ"
							placeholder="ประเทศ"
							keyProps="country"
							value={this.state.country}
							onInputChange={this.handleInputChange}
							disabled={false}
						/>
					</div>
					<div className="column is-3 buttons has-addons is-centered" style={{ paddingTop: 45 }}>
						<button type="button" className="button is-success" onClick={this.confirmChange}>
							{this.props.confirmText ? this.props.confirmText : 'แก้ไข'}
						</button>
						{this.props.confirmDelete ? (
							<button type="button" className="button is-danger" onClick={this.delete}>
								{this.props.deleteText ? this.props.deleteText : 'ลบ'}
							</button>
						) : null}
						<button type="button" className="button" onClick={this.cancelChange}>
							ยกเลิก
						</button>
					</div>
				</div>
			);
		} else {
			return (
				<div onClick={this.toggleMode} className="clickable">
					{this.props.children}
				</div>
			);
		}
	}
}
