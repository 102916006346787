import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment-timezone';

import BackButtonRouter from '../../../Components/BackButtonRouter';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

import LoadingCenter from '../../../Components/LoadingCenter';
import AuthHelper from '../../../Helper/AuthHelper';
import AppConfig from '../../../AppConfig';
import DateRangeLabel from '../../../Components/DateRangeLabel';
import { DateTimeLabel } from '../../../Components/DateLabel';
import InputRow from '../../../Components/InputRow';
import APIGet from '../../../API/APIGet';
import APIHelper from '../../../Helper/APIHelper';
import APIPost from '../../../API/APIPost';
import ThingsToSelect from '../../../Helper/ThingsToSelect';

const PlanningPage = ({ history, location }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [filter, setFilter] = useState({
		name: '',
		startDate: null,
		returnDate: null,
	});
	const [plans, setPlans] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [countries, setCountries] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);

	const buildFilterFromQuery = query => {
		return {
			name: query.name || '',
			startDate: query.startDate ? moment(new Date(query.startDate)) : null,
			returnDate: query.returnDate ? moment(new Date(query.returnDate)) : null,
			companyId: query.companyId || '',
			countryId: query.countryId || '',
		};
	};

	const searchFromFilter = async filter => {
		setIsLoading(true);

		const body = {
			departureDate: filter.startDate ? filter.startDate.format('YYYY-MM-DD') : '',
			returnDate: filter.returnDate ? filter.returnDate.format('YYYY-MM-DD') : '',
			companyId: filter.companyId ? filter.companyId : '',
			countryId: filter.countryId ? filter.countryId : '',
			name: filter.name ? filter.name : '',
		};
		let response = await APIGet('incentivePlan', body);
		if (APIHelper.handleAPIResponse(this, response)) {
			console.log('search response', response.data);
			setIsLoading(false);
			setPlans([...response.data]);
		} else {
			toast.error('ไม่สามารถดึงข้อมูลโปรแกรมได้ กรุณาลองใหม่อีกครั้ง');
			setIsLoading(false);
		}
	};

	const getCompanies = async () => {
		let response = await APIGet('companies');
		if (APIHelper.handleAPIResponse(response)) {
			setCompanies(ThingsToSelect(response.data, true));
		}
	};

	const getCountries = async () => {
		let response = await APIGet('countries');
		if (APIHelper.handleAPIResponse(response)) {
			setCountries(ThingsToSelect(response.data, true));
		}
	};

	const getSearchUrl = useMemo(() => {
		const params = [];

		if (filter.startDate) params.push(`startDate=${filter.startDate.format('YYYY-MM-DD')}`);
		if (filter.returnDate) params.push(`returnDate=${filter.returnDate.format('YYYY-MM-DD')}`);
		if (filter.name) params.push(`name=${filter.name}`);
		if (filter.companyId) params.push(`companyId=${filter.companyId}`);
		if (filter.countryId) params.push(`countryId=${filter.countryId}`);

		return params.length > 0 ? `?${params.join('&&')}` : '';
	}, [filter]);

	const createNewPlan = async () => {
		const template = {
			id: null,
			name: 'New plan',
			departureDate: moment().format('YYYY-MM-DD'),
			returnDate: moment()
				.add(1, 'days')
				.format('YYYY-MM-DD'),
			staff: {
				id: AuthHelper.getUserDetail().id,
			},
		};

		let response = await APIPost('incentivePlan', template);

		if (APIHelper.handleAPIResponse(this, response)) {
			window.location.href = `planning-incentive/${response.data.id}`;
		} else {
			toast.error('ไม่สามารถสร้าง plan ใหม่ได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	const clonePlan = async planId => {
		let response = await APIPost('incentivePlan/clone', { id: planId });

		if (APIHelper.handleAPIResponse(this, response)) {
			toast.success('Copy plan สำเร็จ');
			window.location.href = `/programs/planning-incentive/${response.data.id}`;
		} else {
			toast.error('ไม่สามารถ copy plan ได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	useEffect(() => {
		getCompanies();
		getCountries();
	}, []);

	useEffect(() => {
		const query = queryString.parse(location.search);
		if (query.companyId) {
			const selectedCompany = companies.find(c => c.value === parseInt(query.companyId));
			setSelectedCompany(selectedCompany);
		}
	}, [companies]);

	useEffect(() => {
		const query = queryString.parse(location.search);
		if (query.countryId) {
			const selectedCountry = countries.find(c => c.value === parseInt(query.countryId));
			setSelectedCountry(selectedCountry);
		}
	}, [countries]);

	useEffect(() => {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			const initialQuery = queryString.parse(location.search);
			const initialFilter = buildFilterFromQuery(initialQuery);
			setFilter(initialFilter);
			searchFromFilter(initialFilter);

			return () => {};
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}, [location]);

	const onFilterChange = (key, value) => {
		setFilter(prevFilter => ({
			...prevFilter,
			[key]: value,
		}));
	};

	if (isLoading) {
		return <LoadingCenter />;
	}

	return (
		<div className="container">
			<BackButtonRouter link={AppConfig.appPath + '/'} title="ดูทั้งหมด" />
			<div className="column is-12">
				<h2 className="title is-12" style={{ fontSize: '2.5rem' }}>
					INCENTIVE PLANNING
				</h2>
			</div>

			<div className="column is-2" />
			<div className="columns">
				<div className="column is-2">
					<label className="label">ค้นหา</label>

					{/* <div style={{ marginTop: -8 }}> */}
					<InputRow
						placeholder="ค้นหาจากชื่อแพลน"
						keyProps="name"
						value={filter.name}
						onInputChange={onFilterChange}
						disabled={false}
						isMandatory={false}
					/>
					{/* </div> */}
				</div>
				<div className="column is-2 control">
					<label className="label">วันเริ่ม</label>

					<div className="control has-icons-right">
						<DatePicker
							selected={filter.startDate}
							onChange={newDate => onFilterChange('startDate', newDate)}
							dateFormat="DD MMM YYYY"
							className="input float-left"
							placeholderText="วันเริ่ม"
						/>
						<span className="icon is-small is-right">
							<FontAwesomeIcon icon={faCalendarDays} />
						</span>
					</div>
				</div>
				<div className="column is-2">
					<label className="label">วันสิ้นสุด</label>

					<div className="control has-icons-right">
						<DatePicker
							selected={filter.returnDate}
							onChange={newDate => onFilterChange('returnDate', newDate)}
							dateFormat="DD MMM YYYY"
							className="input float-left"
							placeholderText="วันสิ้นสุด"
						/>
						<span className="icon is-small is-right">
							<FontAwesomeIcon icon={faCalendarDays} />
						</span>
					</div>
				</div>
				<div className="column is-2">
					<label className="label">ลูกค้า</label>
					<Select
						name="company"
						value={selectedCompany}
						clearable={false}
						onChange={value => {
							setSelectedCompany(value);
							onFilterChange('companyId', value.value);
						}}
						options={companies}
					/>
				</div>
				<div className="column is-2">
					<label className="label">ประเทศ</label>
					<Select
						name="country"
						value={selectedCountry}
						clearable={false}
						onChange={value => {
							setSelectedCountry(value);
							onFilterChange('countryId', value.value);
						}}
						options={countries}
					/>
				</div>
				<div className="column is-1" style={{ paddingTop: 45 }}>
					<Link to={`${AppConfig.appPath}/programs/planning-incentive${getSearchUrl}`}>
						<button className="button is-link">ค้นหา</button>
					</Link>
				</div>
				<div className="column is-1" style={{ paddingTop: 45 }}>
					<Link to={`${AppConfig.appPath}/programs/planning-incentive`}>
						<button
							className="button"
							onClick={() => {
								setSelectedCompany(null);
								setSelectedCountry(null);
							}}
						>
							clear
						</button>
					</Link>
				</div>
			</div>
			<div className="columns">
				<div className="column is-12">
					<button
						className="button is-success"
						onClick={async e => {
							e.preventDefault();
							await createNewPlan();
						}}
					>
						+ เพิ่ม
					</button>

					{/* <Link className="button is-success" to={AppConfig.appPath + '/programs/newincentive'}>
							+ เพิ่ม
							</Link> */}
				</div>
			</div>
			<div className="table-container">
				<table className="table is-hoverable is-fullwidth is-striped is-bordered">
					<thead>
						<tr className="table-header">
							<th width="20%">ชื่อแพลน</th>
							<th width="15%">ไป - กลับ</th>
							<th width="15%">ลูกค้า</th>
							<th width="15%">ประเทศ</th>
							<th width="10%">สร้างโดย</th>
							<th width="10%">สร้างเมื่อ</th>
							<th width="10%">แก้ไขล่าสุด</th>
							<th width="5%" />
						</tr>
					</thead>
					<tbody>
						{plans.map(plan => (
							<tr key={`plan-${plan.id}`}>
								<td>
									<Link to={AppConfig.appPath + '/programs/planning-incentive/' + plan.id}>{plan.name}</Link>
								</td>
								<td style={{ textAlign: 'center' }}>
									<DateRangeLabel startDate={plan.departureDate} endDate={plan.returnDate} />
								</td>
								<td style={{ textAlign: 'center' }}>{plan.company != null ? plan.company.name : '-'}</td>
								<td style={{ textAlign: 'center' }}>
									{plan.countries != null && plan.countries.length > 0
										? plan.countries.reduce((acc, c, i) => {
												return acc + (i > 0 ? ',' : '') + c.label;
										  }, '')
										: '-'}
								</td>
								{/* <td style={{ textAlign: 'center' }}>{plan.company ? plan.company.name : ''}</td>
								<td style={{ textAlign: 'center' }}>
									{plan.countries
										? plan.countries.map((country, index) => {
												return (
													<span key={index}>
														{country.label}
														{index < plan.countries.length - 1 ? ', ' : ''}
													</span>
												);
										  })
										: ''}
								</td> */}
								<td style={{ textAlign: 'center' }}>{plan.staff ? plan.staff.name : null}</td>
								<td style={{ textAlign: 'center' }}>
									<DateTimeLabel date={plan.created_at} />
								</td>
								<td style={{ textAlign: 'center' }}>
									<DateTimeLabel date={plan.updated_at} />
								</td>
								<td style={{ textAlign: 'center' }}>
									<button
										className="button is-link"
										onClick={async e => {
											e.preventDefault();
											await clonePlan(plan.id);
										}}
									>
										<FontAwesomeIcon icon="copy" />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<br />
		</div>
	);
};

export default withRouter(PlanningPage);

const styles = {
	tableHeaderStyle: {
		color: '#ffffff',
		textAlign: 'center',
	},
	tableRowDataStyle: {
		textAlign: 'center',
	},
};
