import React, { useMemo } from 'react';
import { EditableDateSwitch } from '../../../../Components/EditableDateSwitch';
import moment from 'moment';
import { EditableMultiSelectSwitch } from '../../../../Components/EditableMultiSelectSwitch';
import { EditableSelectSwitch } from '../../../../Components/EditableSelectSwitch';

export const DetailInput = ({ state, onDepartureChange, onArrivalChange, onLandOperatorChange, onStaffChange, onCountryChanged, onCompanyChanged }) => {
	const { isEditing, departureDate, returnDate, staffs, staff, allLandOperators, landOperators, allCountries, countries, allCompanies, company } = state;

	const countryCsv = useMemo(() => {
		return countries.map(country => country.label).join(',');
	}, [countries]);

	const landOperatorCsv = useMemo(() => {
		return landOperators.map(landOperator => landOperator.label).join(',');
	}, [landOperators]);

	const departureDateMoment = useMemo(() => {
		if (moment.isMoment(departureDate)) {
			return departureDate;
		} else {
			return moment(departureDate);
		}
	}, [departureDate]);

	const returnDateMoment = useMemo(() => {
		if (moment.isMoment(returnDate)) {
			return returnDate;
		} else {
			return moment(returnDate);
		}
	}, [returnDate]);

	return (
		<div>
			<div className="columns">
				<div className="column is-3 control">
					<EditableDateSwitch isEditing={isEditing} title="ไป" value={departureDateMoment} onChange={onDepartureChange} showIcon={true}>
						<p>{departureDate ? departureDateMoment.format('DD MMM YYYY') : 'None'}</p>
					</EditableDateSwitch>
				</div>
				<div className="column is-3 control">
					<EditableDateSwitch isEditing={isEditing} title="กลับ" value={returnDateMoment} onChange={onArrivalChange} showIcon={true}>
						<p>{returnDate ? returnDateMoment.format('DD MMM YYYY') : 'None'}</p>
					</EditableDateSwitch>
				</div>
				<div className="column is-3 control">
					<EditableSelectSwitch
						title="ลูกค้า"
						name="company"
						value={company}
						clearable={false}
						onChange={data => {
							onCompanyChanged(data);
						}}
						options={allCompanies}
						isEditing={isEditing}
					>
						<p>{company ? company.label : 'ไม่ระบุ'}</p>
					</EditableSelectSwitch>
				</div>
				<div className="column is-3 control">
					<EditableSelectSwitch
						title="พนักงาน"
						name="creator"
						value={staff ? { value: staff.id, label: staff.name } : ''}
						clearable={false}
						onChange={staff => {
							const selectedStaff = staffs.filter(s => s.id === staff.value)[0];
							// console.log(selectedStaff);
							onStaffChange(selectedStaff);
						}}
						options={staffs.map(staff => ({ value: staff.id, label: staff.name }))}
						isEditing={isEditing}
					>
						<p>{staff ? staff.name : 'ไม่ระบุ'}</p>
					</EditableSelectSwitch>
				</div>
			</div>
			<div className="columns">
				<div className="column is-6 control">
					<EditableMultiSelectSwitch
						title="Land Operator"
						name="landOperator"
						value={landOperators}
						clearable={false}
						onChange={data => {
							onLandOperatorChange(data);
						}}
						options={allLandOperators}
						isEditing={isEditing}
					>
						{landOperatorCsv}
					</EditableMultiSelectSwitch>
				</div>
				<div className="column is-6 control">
					<EditableMultiSelectSwitch
						title="ประเทศ"
						name="countries"
						value={countries}
						clearable={false}
						onChange={data => {
							onCountryChanged(data);
						}}
						options={allCountries}
						isEditing={isEditing}
					>
						{countryCsv}
					</EditableMultiSelectSwitch>
				</div>
			</div>
		</div>
	);
};
