import React, { useReducer, useEffect, useMemo, useCallback } from 'react';

import { toast } from 'react-toastify';

import LoadingCenter from '../../../../Components/LoadingCenter';
import BackButtonRouter from '../../../../Components/BackButtonRouter';

import AuthHelper from '../../../../Helper/AuthHelper';

import { EditSave } from './EditSave';
import { TicketTabs } from './TicketTab';
import { SubPlanTable } from './SubPlanTable';
import { DetailInput } from './DetailInput';
import { ExchangeTable } from './ExchangeTable';
import { Tab } from './Tabs';
import { SummaryTab } from './SummaryTab';
import EditableText from '../../../../Components/EditableText';
import { EditableTextSwitch } from '../../../../Components/EditableTextSwitch';
import moment from 'moment';
import APIGet from '../../../../API/APIGet';
import APIHelper from '../../../../Helper/APIHelper';
import { LandAssessmentTabs } from './LandAssessmentTab';
import { VisaTabs } from './VisaTab';
import { PLANNING_REDUCER_ACTION, calculateSubPlanSummary, planningReducer } from './planningReducer';
import { GeneralTab } from './GeneralTab';
import { SumTable } from './SumTable';
import {
	DEFAULT_COMMISSION,
	DEFAULT_OTHER,
	DEFAULT_TOUR_LEADER,
	DEFAULT_TICKET,
	DEFAULT_LAND_ASSESSMENT,
	DEFAULT_VISA,
} from '../../../../StaticData/SubPlanTemplate';
import APIDelete from '../../../../API/APIDelete';
import APIPut from '../../../../API/APIPut';
import APIPost from '../../../../API/APIPost';
import { SingleSupplementTable } from './SingleSupplement';
import ThingsToSelect from '../../../../Helper/ThingsToSelect';

const PlanningDetail = props => {
	const { planningId } = props.match.params;
	const [state, dispatch] = useReducer(planningReducer, {
		isLoading: true,
		name: null,
		departureDate: null,
		returnDate: null,
		landOperator: null,
		allLandOperators: [],
		landOperators: [],
		allCountries: [],
		allCompanies: [],
		company: null,
		countries: [],
		isEditing: false,
		staff: null,
		exchangeRates: [],
		staffs: [],
		airlines: [],
		selectedTab: 'summary',
		incentiveSubPlans: [],
		singleTraveler: {
			price: 0,
			currency: null,
			profit: 0,
			netPrice: 0,
			person: 0,
		},
		remark: null,
		displaySubPlanIndex: -1, // should be readjusted to the selected one after getting planning from api
		summary: {
			tickets: [],
			landAssessments: [],
			visas: [],
			tourLeaders: [],
			others: [],
			commissions: [],
			totals: [],
			childDiscounts: [],
			expectProfits: [],
			grandTotals: [],
			salePrices: [],
			totalIncomes: [],
			totalCosts: [],
			totalMargins: [],
			singleTravelerNetPrices: [],
			singleTravelersSalePrices: [],
		},
	});

	useEffect(() => {
		if (AuthHelper.hasRight('Staff', 'Reporter', 'Finance', 'Admin')) {
			getInitialData();
		} else {
			toast.warn('คุณไม่มีสิทธิ์ใช้งานในหน้านี้');
		}
	}, []);

	const updateState = newState => {
		dispatch({
			type: PLANNING_REDUCER_ACTION.UPDATE_JSON,
			newState,
		});
	};

	const updateActiveSubPlanTab = (index, key, data, countryId) => {
		dispatch({
			type: PLANNING_REDUCER_ACTION.UPDATE_ACTIVE_SUB_PLAN_TAB,
			index,
			key,
			newState: data,
			countryId,
		});
	};

	const updateActiveSubPlan = (index, data) => {
		dispatch({
			type: PLANNING_REDUCER_ACTION.UPDATE_ACTIVE_SUB_PLAN,
			index,
			newState: data,
		});
	};

	const onRemarkChange = remark => {
		updateState({ remark });
	};

	const getInitialData = async () => {
		const [requestPlan, requestStaffs, requestAirlines, requestLandOperators, requestCountries, requestCompanies] = await Promise.allSettled([
			APIGet(`incentivePlan/${planningId}`),
			APIGet('staffs'),
			APIGet('airlines'),
			APIGet('landOperators'),
			APIGet('countries'),
			APIGet('companies'),
		]);
		if (
			APIHelper.handleAPIResponse(
				this,
				requestPlan.value,
				requestStaffs.value,
				requestAirlines.value,
				requestLandOperators.value,
				requestCountries.value,
				requestCompanies.value
			)
		) {
			const staffs = requestStaffs.value.data.filter(staff => staff.authRole === 'Staff');
			const airlines = requestAirlines.value.data;
			const plan = requestPlan.value.data;
			const allLandOperators = ThingsToSelect(requestLandOperators.value.data);
			const allCountries = ThingsToSelect(requestCountries.value.data);
			const allCompanies = ThingsToSelect(requestCompanies.value.data);
			const company = plan.company ? allCompanies.find(company => company.value === plan.company.id) : null;

			// console.log('plan', plan);
			// console.log('staffs', staffs);

			let displaySubPlanIndex = plan.incentiveSubPlans.findIndex((subPlan, index) => subPlan.isSelected);
			if (displaySubPlanIndex === -1) {
				displaySubPlanIndex = 0;
			}

			updateState({
				isLoading: false,
				staffs,
				airlines,
				...plan,
				singleTraveler: plan.singleTraveler
					? plan.singleTraveler
					: {
							price: 0,
							currency: null,
							profit: 0,
							netPrice: 0,
							person: 0,
					  },
				displaySubPlanIndex,
				summary: calculateSubPlanSummary(plan.incentiveSubPlans),
				allLandOperators,
				allCountries,
				allCompanies,
				company,
			});

			// await fetchPlanning(planningId);
		} else {
			toast.error('ไม่สามารถดึงข้อมูลได้');
		}
	};

	const clonePlan = async planId => {
		let response = await APIPost('incentivePlan/clone', { id: planId });

		if (APIHelper.handleAPIResponse(this, response)) {
			toast.success('Copy plan สำเร็จ');
			window.location.href = `/programs/planning-incentive/${response.data.id}`;
		} else {
			toast.error('ไม่สามารถ copy plan ได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	const deletePlan = async () => {
		let response = await APIDelete(`incentivePlan/${planningId}`);

		if (APIHelper.handleAPIResponse(this, response)) {
			window.history.go(-1);
		} else {
			console.log(response.data);
			toast.error('ไม่สามารถลบ plan ได้ กรุณาลองใหม่อีกครั้ง');
		}
	};

	const updatePlan = async () => {
		const { name, departureDate, returnDate, landOperator, staff, exchangeRates, incentiveSubPlans, summary, remark, singleTraveler } = state;
		const body = {
			id: planningId,
			name,
			departureDate: moment(departureDate).format('YYYY-MM-DD'),
			returnDate: moment(returnDate).format('YYYY-MM-DD'),
			staff,
			landOperator,
			landOperatorIds: state.landOperators.map(landOperator => landOperator.value),
			countryIds: state.countries.map(country => country.value),
			companyId: state.company ? state.company.value : null,
			exchangeRates: exchangeRates.map(rate => ({ ...rate, date: moment(rate.date).format('YYYY-MM-DD') })),
			incentiveSubPlans: incentiveSubPlans.map((subPlan, i) => {
				subPlan.totalIncome = summary.totalIncomes[i].adult + summary.totalIncomes[i].child;
				subPlan.totalExpense = summary.totalCosts[i].adult + summary.totalCosts[i].child;
				subPlan.totalProfit = summary.totalMargins[i].adult + summary.totalMargins[i].child;
				return subPlan;
			}),
			remark,
			singleTraveler,
		};

		console.log('update plan request', body);
		// console.log('update plan request', JSON.stringify(body));

		const response = await APIPost(`incentivePlan`, body);

		// console.log('update plan response', response);

		if (APIHelper.handleAPIResponse(this, response)) {
			toast.success('update plan สำเร็จ');

			return true;
		} else {
			console.log(response.data);
			toast.error('ไม่สามารถ update plan ได้ กรุณาลองใหม่อีกครั้ง');
		}

		return false;
	};

	const { selectedSubPlan, focusingSubPlanIndex } = useMemo(() => {
		try {
			if (state.isEditing) {
				for (let i = 0; i < state.incentiveSubPlans.length; i++) {
					if (state.incentiveSubPlans[i].isSelected) {
						// console.log('found at index', i);
						return {
							selectedSubPlan: state.incentiveSubPlans[i],
							focusingSubPlanIndex: i,
						};
					}
				}
			} else {
				return {
					selectedSubPlan: state.incentiveSubPlans[state.displaySubPlanIndex],
					focusingSubPlanIndex: -1, // doesn't matter for view mode. It's used during update active subplan
				};
			}
		} catch (ex) {
			console.log(ex);
		}

		return {
			selectedSubPlan: null,
			focusingSubPlanIndex: -1,
		};
	}, [state.incentiveSubPlans, state.isEditing, state.displaySubPlanIndex]);

	const _onCountryChanged = useCallback(
		newCountries => {
			const incentiveSubPlans = JSON.parse(JSON.stringify(state.incentiveSubPlans));
			for (let i = 0; i < incentiveSubPlans.length; i++) {
				const tickets = JSON.parse(JSON.stringify(incentiveSubPlans[i].tickets));
				const landAssessments = JSON.parse(JSON.stringify(incentiveSubPlans[i].landAssessments));
				const visas = JSON.parse(JSON.stringify(incentiveSubPlans[i].visas));
				const tourLeaders = JSON.parse(JSON.stringify(incentiveSubPlans[i].tourLeaders));
				const commissions = JSON.parse(JSON.stringify(incentiveSubPlans[i].commissions));
				const others = JSON.parse(JSON.stringify(incentiveSubPlans[i].others));

				// find added countries from newCountries
				const addedCountries = newCountries.filter(newCountry => !state.countries.find(country => country.value === newCountry.value));
				for (const addedCountry of addedCountries) {
					tickets[addedCountry.value] = [{ ...DEFAULT_TICKET }];
					landAssessments[addedCountry.value] = [...DEFAULT_LAND_ASSESSMENT];
					visas[addedCountry.value] = [{ ...DEFAULT_VISA }];
					tourLeaders[addedCountry.value] = [{ ...DEFAULT_TOUR_LEADER }];
					others[addedCountry.value] = [{ ...DEFAULT_OTHER }];
					commissions[addedCountry.value] = [{ ...DEFAULT_COMMISSION }];
				}

				// find removed countries from newCountries
				const removedCountries = state.countries.filter(country => !newCountries.find(newCountry => newCountry.value === country.value));
				for (const removedCountry of removedCountries) {
					delete tickets[removedCountry.value];
					delete landAssessments[removedCountry.value];
					delete visas[removedCountry.value];
					delete tourLeaders[removedCountry.value];
					delete others[removedCountry.value];
					delete commissions[removedCountry.value];
				}

				incentiveSubPlans[i].tickets = tickets;
				incentiveSubPlans[i].landAssessments = landAssessments;
				incentiveSubPlans[i].visas = visas;
				incentiveSubPlans[i].tourLeaders = tourLeaders;
				incentiveSubPlans[i].others = others;
				incentiveSubPlans[i].commissions = commissions;
			}

			console.log('on country changed called', newCountries, incentiveSubPlans);
			updateState({ countries: newCountries, incentiveSubPlans });
		},
		[state.incentiveSubPlans, state.countries]
	);

	if (state.isLoading) {
		return <LoadingCenter />;
	}

	// data
	const { name } = state;

	// control
	const { isEditing, selectedTab, airlines, exchangeRates } = state;

	return (
		<div className="container">
			<BackButtonRouter title="INCENTIVE Planning" />
			<div className="columns">
				<div className="column is-12">
					<EditableTextSwitch isEditing={isEditing} value={name} onChange={name => updateState({ name })}>
						<h1 className="title">{name}</h1>
					</EditableTextSwitch>
					{/* <InputRow value={planName} /> */}
					<EditableText />
				</div>
			</div>

			<EditSave
				state={state}
				onEdit={() => {
					dispatch({
						type: PLANNING_REDUCER_ACTION.UPDATE_JSON,
						newState: {
							isEditing: true,
						},
					});
				}}
				onSave={async () => {
					const result = await updatePlan();

					if (result) {
						dispatch({
							type: PLANNING_REDUCER_ACTION.UPDATE_JSON,
							newState: {
								isEditing: false,
							},
						});
					}
				}}
				onCancel={() => window.location.reload()}
				onDelete={async () => await deletePlan()}
				onCopy={async () => await clonePlan(planningId)}
			/>

			<DetailInput
				state={state}
				onDepartureChange={date => updateState({ departureDate: date })}
				onArrivalChange={date => updateState({ returnDate: date })}
				onLandOperatorChange={data => updateState({ landOperators: data })}
				onStaffChange={staff => updateState({ staff })}
				onCountryChanged={data => _onCountryChanged(data)}
				onCompanyChanged={data => updateState({ company: data })}
			/>
			<div style={{ height: '30px' }} />
			<ExchangeTable state={state} onChange={exchangeRates => updateState({ exchangeRates })} />
			<div style={{ height: '30px' }} />
			<SubPlanTable
				state={state}
				onChange={incentiveSubPlans => updateState({ incentiveSubPlans })}
				onChangeDisplaySubPlan={displaySubPlanIndex => updateState({ displaySubPlanIndex })}
			/>
			<div style={{ height: '30px' }} />
			<SingleSupplementTable state={state} onChange={singleTraveler => updateState({ singleTraveler })} />
			<div style={{ height: '30px' }} />
			<Tab selectedTab={selectedTab} onTabClick={selectedTab => updateState({ selectedTab })} />
			<SummaryTab
				selectedSubPlan={selectedSubPlan}
				state={state}
				onChange={subPlan => {
					console.log('new plan', subPlan);
					updateActiveSubPlan(focusingSubPlanIndex, subPlan);
				}}
				onRemarkChange={onRemarkChange}
			/>
			<TicketTabs
				selectedSubPlan={selectedSubPlan}
				state={state}
				onChange={(tickets, countryId) => {
					updateActiveSubPlanTab(focusingSubPlanIndex, 'tickets', tickets, countryId);
				}}
				airlines={airlines}
				exchangeRates={exchangeRates}
				onRemarkChange={onRemarkChange}
			/>
			<LandAssessmentTabs
				selectedSubPlan={selectedSubPlan}
				state={state}
				onChange={(landAssessments, countryId) => {
					updateActiveSubPlanTab(focusingSubPlanIndex, 'landAssessments', landAssessments, countryId);
				}}
				onRemarkChange={onRemarkChange}
			/>
			<VisaTabs
				selectedSubPlan={selectedSubPlan}
				state={state}
				onChange={(visas, countryId) => {
					updateActiveSubPlanTab(focusingSubPlanIndex, 'visas', visas, countryId);
				}}
				onRemarkChange={onRemarkChange}
			/>
			<GeneralTab
				tabName={'tourLeader'}
				displayName={'หัวหน้าทัวร์'}
				records={selectedSubPlan ? selectedSubPlan.tourLeaders : null}
				state={state}
				defaultRecord={DEFAULT_TOUR_LEADER}
				onChange={(tourLeaders, countryId) => {
					updateActiveSubPlanTab(focusingSubPlanIndex, 'tourLeaders', tourLeaders, countryId);
				}}
				onRemarkChange={onRemarkChange}
			/>
			<GeneralTab
				tabName={'etc'}
				displayName={'อื่นๆ'}
				records={selectedSubPlan ? selectedSubPlan.others : null}
				state={state}
				defaultRecord={DEFAULT_OTHER}
				onChange={(others, countryId) => {
					updateActiveSubPlanTab(focusingSubPlanIndex, 'others', others, countryId);
				}}
				onRemarkChange={onRemarkChange}
			/>
			<GeneralTab
				tabName={'commission'}
				displayName={'Commission'}
				records={selectedSubPlan ? selectedSubPlan.commissions : null}
				state={state}
				defaultRecord={DEFAULT_COMMISSION}
				onChange={(commissions, countryId) => {
					updateActiveSubPlanTab(focusingSubPlanIndex, 'commissions', commissions, countryId);
				}}
				onRemarkChange={onRemarkChange}
			/>
			<SumTable state={state} />
			<div style={{ height: 50 }} />
			<EditSave
				state={state}
				onEdit={() => {
					dispatch({
						type: PLANNING_REDUCER_ACTION.UPDATE_JSON,
						newState: {
							isEditing: true,
						},
					});
				}}
				onSave={async () => {
					const result = await updatePlan();

					if (result) {
						dispatch({
							type: PLANNING_REDUCER_ACTION.UPDATE_JSON,
							newState: {
								isEditing: false,
							},
						});
					}
				}}
				onCancel={() => window.location.reload()}
			/>
		</div>
	);
};

export default PlanningDetail;
